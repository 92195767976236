export default {
  firebaseConfig: {
    apiKey: 'AIzaSyCqZrLTqsJ5s3Y7vgGrOm8NakVB5DHh_58',
    authDomain: 'doryhealth.firebaseapp.com',
    databaseURL: 'https://doryhealth-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'doryhealth',
    storageBucket: 'doryhealth.appspot.com',
    messagingSenderId: '348967463237',
    appId: '1:348967463237:web:31bcaf9ed78988bbcff62c',
    measurementId: 'G-L53LT73WRQ',
  },
}
